exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-ayurveda-index-tsx": () => import("./../../../src/pages/ayurveda/index.tsx" /* webpackChunkName: "component---src-pages-ayurveda-index-tsx" */),
  "component---src-pages-contactus-index-tsx": () => import("./../../../src/pages/contactus/index.tsx" /* webpackChunkName: "component---src-pages-contactus-index-tsx" */),
  "component---src-pages-custom-index-tsx": () => import("./../../../src/pages/custom/index.tsx" /* webpackChunkName: "component---src-pages-custom-index-tsx" */),
  "component---src-pages-destress-index-tsx": () => import("./../../../src/pages/destress/index.tsx" /* webpackChunkName: "component---src-pages-destress-index-tsx" */),
  "component---src-pages-faq-index-tsx": () => import("./../../../src/pages/faq/index.tsx" /* webpackChunkName: "component---src-pages-faq-index-tsx" */),
  "component---src-pages-home-index-tsx": () => import("./../../../src/pages/home/index.tsx" /* webpackChunkName: "component---src-pages-home-index-tsx" */),
  "component---src-pages-immunity-index-tsx": () => import("./../../../src/pages/immunity/index.tsx" /* webpackChunkName: "component---src-pages-immunity-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-jointpain-index-tsx": () => import("./../../../src/pages/jointpain/index.tsx" /* webpackChunkName: "component---src-pages-jointpain-index-tsx" */),
  "component---src-pages-login-adminpanel-index-tsx": () => import("./../../../src/pages/login/adminpanel/index.tsx" /* webpackChunkName: "component---src-pages-login-adminpanel-index-tsx" */),
  "component---src-pages-login-index-tsx": () => import("./../../../src/pages/login/index.tsx" /* webpackChunkName: "component---src-pages-login-index-tsx" */),
  "component---src-pages-nerve-index-tsx": () => import("./../../../src/pages/nerve/index.tsx" /* webpackChunkName: "component---src-pages-nerve-index-tsx" */),
  "component---src-pages-packages-index-tsx": () => import("./../../../src/pages/packages/index.tsx" /* webpackChunkName: "component---src-pages-packages-index-tsx" */),
  "component---src-pages-panchakarma-index-tsx": () => import("./../../../src/pages/panchakarma/index.tsx" /* webpackChunkName: "component---src-pages-panchakarma-index-tsx" */),
  "component---src-pages-privacypolicy-index-tsx": () => import("./../../../src/pages/privacypolicy/index.tsx" /* webpackChunkName: "component---src-pages-privacypolicy-index-tsx" */),
  "component---src-pages-rejuvenation-index-tsx": () => import("./../../../src/pages/rejuvenation/index.tsx" /* webpackChunkName: "component---src-pages-rejuvenation-index-tsx" */),
  "component---src-pages-seniors-index-tsx": () => import("./../../../src/pages/seniors/index.tsx" /* webpackChunkName: "component---src-pages-seniors-index-tsx" */),
  "component---src-pages-skin-index-tsx": () => import("./../../../src/pages/skin/index.tsx" /* webpackChunkName: "component---src-pages-skin-index-tsx" */),
  "component---src-pages-sleepwell-index-tsx": () => import("./../../../src/pages/sleepwell/index.tsx" /* webpackChunkName: "component---src-pages-sleepwell-index-tsx" */),
  "component---src-pages-terms-index-tsx": () => import("./../../../src/pages/terms/index.tsx" /* webpackChunkName: "component---src-pages-terms-index-tsx" */),
  "component---src-pages-treatments-index-tsx": () => import("./../../../src/pages/treatments/index.tsx" /* webpackChunkName: "component---src-pages-treatments-index-tsx" */),
  "component---src-pages-weightloss-index-tsx": () => import("./../../../src/pages/weightloss/index.tsx" /* webpackChunkName: "component---src-pages-weightloss-index-tsx" */),
  "component---src-pages-yoga-index-tsx": () => import("./../../../src/pages/yoga/index.tsx" /* webpackChunkName: "component---src-pages-yoga-index-tsx" */)
}

